import React from 'react';
import SocialLinks from '../SocialLinks';
import './Contact.css';

const Contact = props => {
  return (
    <section id="contact">
      <div className="container">
        <div className="heading-wrapper">
          <div className="heading">
            <p className="title">
              Want to <br/>
              contact me?
            </p>
            <p className="separator"/>
            <p className="subtitle">
              Please, send an email to {''}
              <span className="mail">
                robin.kreuger2000
                <i className="fas fa-at at"/>
                gmail.com
              </span>
            </p>
            <p className="subtitle">
              Or give me a ring under +31 6 39 14 95 41
            </p>

          </div>
          <SocialLinks/>
        </div>
      </div>
    </section>
  );
};

export default Contact;
