import React from 'react';

const SocialLinks = props => {
  return (
    <div className="social">
      <a
        href="https://www.linkedin.com/in/rkreuger/"
        target="_blank"
        rel="noopener noreferrer"
        title="Link to his LinkedIn profile"
      >
        {' '}
        <i className="fab fa-linkedin-in" />
      </a>
      <a
        id="profile-link"
        href="https://www.instagram.com/robin.kreuger/"
        target="_blank"
        rel="noopener noreferrer"
        title="Link to his Instgram page"
      >
        {' '}
        <i className="fab fa-instagram" />
      </a>
    </div>
  );
};

export default SocialLinks;
