import React from 'react';

const Project = props => {
  const tech = {
    livestream: 'fab fa-youtube',
    photo: 'fas fa-image',
    music: 'fab fa-itunes-note',
    light: 'fas fa-lightbulb'
  };

  const link = props.link || 'http://';

  return (
    <div className="project">
      <a className="project-link" href={link} target="_blank" rel="noopener noreferrer">
        <img className="project-image" src={props.img} alt={'Screenshot of ' + props.title} />
      </a>
      <div className="project-details">
        <div className="project-tile">
          <p className="icons">
            {props.tech.split(' ').map(t => (
              <i className={tech[t]} key={t} />
            ))}
          </p>
          {props.title}{' '}
        </div>
        {props.children}
        <div className="buttons">
          <a href={link} target="_blank" rel="noopener noreferrer">
            Show more  <i className="fas fa-external-link-alt" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Project;
