import React from 'react';
import './About.css';

const About = props => {
  return (
    <section id="about">
      <div className="wrapper">
        <article>
          <div className="title">
            <h3>Who's this guy?</h3>
            <p className="separator" />
          </div>
          <div className="desc full">
            <h4 className="subtitle">My name is Robin.</h4>
            <p>
              I am a freelance photographer and a developer in my free time.
            </p>
            <p>
              I really enjoy solving problems as well as making things pretty and easy to use. I
              can't stop learning new things; the more, the better. I also love to be active, anything
              with a ball to be precise, in particular waterpolo and handball!
            </p>
          </div>
          <div className="title">
            <h3>What does he do?</h3>
            <p className="separator" />
          </div>
          <div className="desc">
            <h4 className="subtitle">I'm a student.</h4>
            <p>
              I earned my Bachelor's in Business and Information Technology in 2023, where I balanced
              studies with hands-on experience on committees. During my time at University of Twente, I was a proud member of
              SolarBoatTwente, a student team dedicated to developing a highly efficient solar-powered
              boats, which fueled my passion for sustainable technology.
            </p>
            <p>
              Currently, I am pursuing a Master's in Computer Science, which I began after my Bachelor.
              Alongside my studies, I actively volunteer in various projects, honing my skills and
              giving back to the community. My diverse background and commitment to learning make me
              an enthusiastic and reliable team player.
            </p>
          </div>
          <div className="desc">
            <h4 className="subtitle">Also a photographer.</h4>
            <p>
              In addition to my academic pursuits, I am an avid photographer specializing in capturing
              the essence of events, parties, and sports. I enjoy the dynamic challenges of these settings
              and occasionally extend my skills to live streaming, ensuring every moment is vividly documented
              and shared.
            </p>
            <p>
              I also bring my creative talents to event planning, focusing on sound and light design. My
              attention to detail and technical expertise help create memorable experiences, whether for
              intimate gatherings or large-scale events. This work complements my passion for photography,
              enhancing the atmosphere and ensuring everything looks and sounds perfect.
            </p>
          </div>
        </article>
      </div>
    </section>
  );
};

export default About;
