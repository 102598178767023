import React from 'react';
import Project from './Project';
import './Projects.css';
import SBTconcept23 from './Images/SBT23concept.jpg'
import SBTconcept22 from './Images/SBTconcept22.jpg'
import OOFF23 from './Images/OOFF23.jpg'
import SBTconcept21 from './Images/SBT21concept.jpg'
import todo from './Images/ToDo.jpg'
import tnc23 from './Images/TNC.jpg'
import est23 from './Images/ESTreveal.jpg'
import dhc from './Images/ludica.jpg'
import kick from './Images/kickin.jpg'
import bata from './Images/bata.png'
import suso from './Images/suso.jpg'
import sportgala from './Images/sportgala.jpg'
const Projects = props => {
    return (
        <section id="projects">
            <div className="projects-container">
                <div className="heading">
                    <h3 className="title">My Works</h3>
                    <p className="separator"/>
                    <p className="subtitle">
                        Here's a showcase of my recent <u>photography</u> projects. I've been immersed in capturing
                        stunning
                        moments, honing my craft over the past several months. Each project reflects my journey and
                        dedication to the art of photography. Additionally, I've been busy setting
                        up <u>parties</u>, <u>events</u>,
                        and various <u>live music-evenings</u>, where I also handle the speakers, lights, and sound
                        mixing, ensuring a complete
                        and immersive experience. Feel free to explore these below and also check out my <u>live
                        streams</u> for
                        an in person experience at my creative process.
                    </p>
                </div>
                <div className="projects-wrapper">
                    <Project
                        title="DHC/Ludica Gala "
                        img={dhc}
                        tech="photo"
                        link="https://flic.kr/s/aHBqjBtozy"
                    >
                        <small>
                            Portrait pictures in the photo-booth and party pictures
                        </small>
                        <p>
                            During this gala I took pictures of a lot of people at the photo-booth and there after
                            I took pictures on the dancefloor, where a band and DJ were entertaining the visitors.
                        </p>
                    </Project>
                        <Project
                            title="SUT Sport's Gala "
                            img={sportgala}
                            tech="photo"
                            link="https://flic.kr/s/aHBqjBtrS3"
                        >
                            <small>
                                Portrait pictures in the photo-booth and party pictures
                            </small>
                            <p>
                                During this gala I took pictures of a lot of people at the photo-booth and there after
                                I took pictures on the dancefloor, where a band and DJ were entertaining the visitors.
                            </p>

                        </Project>
                        <Project
                            title="Bata 2023"
                            img={bata}
                            tech="photo"
                            link="https://www.batavierenrace.nl/en/photo/2024/album/390%20Ochtendblok%20-%20Motor?page=6"
                        >
                            <small>
                                The Batavierenrace is the largest relay race in the world, with participants running
                                from
                                Nijmegen to Enschede every year.
                            </small>
                            <p>
                                During this event I drove around with my motorcycle taking pictures along the route of
                                the
                                runners and had a jolly good time.
                            </p>
                        </Project>
                        <Project
                            title="Bike Reveal ElectricSuperbike 2023."
                            img={est23}
                            tech="livestream music light"
                            link="https://www.youtube.com/live/Jcn8_JLO6CA?si=xDe-gb67BgK8Oxp4"
                        >
                            <small>
                                A livestream showcasing what the students from ElectricSuperbike have made.
                            </small>
                            <p>
                                Motor Reveal presentation showcasing their Superbike for the 2024 race season.
                                The team of passionate engineering students from the University of Twente and Saxion
                                University of Applied Sciences in the Netherlands is committed to developing sustainable
                                mobility solutions, and their electric superbikes have already made a mark in the racing
                                world.
                            </p>
                        </Project>
                        <Project
                            title="TNC-Party @Piranha on BuBa ."
                            img={tnc23}
                            tech="music light"
                            link="https://www.zpv-piranha.nl/toernooi"
                        >
                            <small>
                                An outdoor silent disco party for the international waterpolo tournament from Piranha
                            </small>
                            <p>
                                An electrifying outdoor silent disco party at the international water polo
                                tournament hosted by Piranha! Dancing under the stars with wireless headphones, grooving
                                to
                                beats from multiple DJs, and enjoying the unique fusion of sports and music. All in all
                                an
                                unforgettable night of silent rhythms and vibrant energy!
                            </p>
                        </Project>
                        <Project
                            title="Kick-In 2023."
                            img={kick}
                            tech="photo"
                            link="https://www.kick-in.media/event/24f76116-a517-4966-9043-33670ab613e6/Kick-In-2023"
                        >
                            <small>
                                The Kick-In is the introduction week of the University of Twente
                            </small>
                            <p>
                                During this event I took pictures at a variety of different events. At parties one could
                                usually find me high-up on roves with my tele-lens, capturing closeups others could not
                                take.
                            </p>
                        </Project>
                        <Project
                            title="SummerSounds 2023."
                            img={suso}
                            tech="photo"
                            link="https://www.summersounds.nl/"
                        >
                            <small>
                                Student festival on last day of the year.
                            </small>
                            <p>
                                All in all a great evening: open-air with live bands and ofcourse beer.
                            </p>
                        </Project>
                        <Project
                            title="Concept Reveal SolarBoat 2023."
                            img={SBTconcept23}
                            tech="livestream"
                            link="https://youtu.be/cR8kmjkCK6U"
                        >
                            <small>
                                A livestream showcasing what the new plans from SolarBoat are.
                            </small>
                            <p>
                                During this Concept Reveal, Solar Boat Twente will present her plans for the year
                                2023-2024.
                                This year a new group of students are excited to work on designing a new solar-powered
                                racing boat.
                            </p>
                        </Project>
                        <Project
                            title="OOFF 2023."
                            img={OOFF23}
                            tech="music light"
                            link="https://futurefactorytwente.nl/"
                        >
                            <small>
                                A party from old student team members for new members.
                            </small>
                            <p>
                                A big party with 150 people, in the FutureFactory in Enschede, with a liveband, DJ and a
                                lot
                                of happy people.
                            </p>
                        </Project>
                        <Project
                            title="Concept Reveal SolarBoat 2022."
                            img={SBTconcept22}
                            tech="livestream"
                            link="https://www.youtube.com/live/9EahDfPdUU8?si=81N6eaSj15nboooB"
                        >
                            <small>
                                A livestream showcasing what the new plans from SolarBoat are.
                            </small>
                            <p>
                                During this Concept Reveal, Solar Boat Twente will present her plans for the year
                                2022-2023.
                                This year a new group of students are excited to work on designing a new solar-powered
                                racing boat.
                            </p>
                        </Project>
                        <Project
                            title="Concept Reveal SolarBoat 2021."
                            img={SBTconcept21}
                            tech="livestream"
                            link="https://www.youtube.com/live/twIwXByTi-8?si=iAHwHfARfJ1Bslt4"
                        >
                            <small>
                                A livestream showcasing what the new plans from SolarBoat are.
                            </small>
                            <p>
                                During this Concept Reveal, Solar Boat Twente will present her plans for the year
                                2021-2022.
                                This year a new group of students are excited to work on designing a new solar-powered
                                racing boat.
                            </p>
                        </Project>
                    </div>
                </div>
        </section>
);
};

export default Projects;
